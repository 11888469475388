import * as React from "react";
import Flag from "react-world-flags";

const SelectOption = ({
  innerRef,
  innerProps,
  label,
  value,
  isSelected,
  isFocused,
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`select-search__option ${isSelected ? "selected" : ""} ${
        isFocused ? "focused" : ""
      }`}
    >
      <Flag
        code={value}
        width="16"
        fallback={<span style={{ width: "16px" }}></span>}
      />
      <span className="ml-1">{label}</span>
    </div>
  );
};

export default SelectOption;
