import React from "react";
import { faGrid } from "@fortawesome/pro-solid-svg-icons/faGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from "react-bootstrap";
import { multiversXApps as apps } from "../../appConfig";

const AppSwitcher = ({ onToggle }) => {
  const hidePopover = () => {
    document.body.click();
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <NavDropdown
      title={
        <div
          aria-label="Switch App"
          className="nav-link-icon flex-fill text-md-center"
        >
          <i className="material-icons m-0">
            <FontAwesomeIcon icon={faGrid} />
          </i>
        </div>
      }
      id="app-switcher-dropdown"
    >
      {apps.map((app) => {
        return (
          <NavDropdown.Item
            key={app.id}
            onClick={hidePopover}
            href={app.to}
            target="_blank"
            rel="noopener noreferrer"
            className=""
          >
            {app.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};
export default AppSwitcher;
