import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { history } from "./helpers";
import Layout from "./components/Layout";
import Home from "./components/Home";

require("./assets/styles/theme.scss");

function App() {
  return (
    <BrowserRouter history={history}>
      <Layout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/:country" element={<Home />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
