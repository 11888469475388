import { countries as countryList } from "countries-list";

const formattedCountries = [];
Object.entries(countryList).forEach(([countryCode, countryDetails]) => {
  formattedCountries.push({
    label: countryDetails.name,
    value: countryCode.toLowerCase(),
  });
});
formattedCountries.push({
  label: "Other",
  value: "other",
});

export default formattedCountries;
