import * as React from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { countries as countryList } from "countries-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { paymentMethods, buyingMethods } from "appConfig";
import { history, reorderFeaturedCountry } from "helpers";
import SelectOption from "./SelectOption";
import formattedCountries from "./formattedCountries";

const intersect = (a, b) => {
  let bSet = new Set(b);
  return [...new Set(a)].filter((x) => bSet.has(x));
};

const Home = () => {
  let { country } = useParams();

  const [selectedCountry, setSelectedCountry] = React.useState("other");
  const [defaultValue, setDefaultValue] = React.useState();
  const [selectedPayments, setSelectedPayments] = React.useState([
    "default",
    "card_payments",
    "bank_transfers",
  ]);
  const [reorderedBuyingMethods, setReorderedBuyingMethods] = React.useState(
    []
  );

  React.useEffect(() => {
    if (!country && window.sessionStorage) {
      let sessionReload = sessionStorage.getItem("reloaded");
      if (!sessionReload) {
        sessionStorage.setItem("reloaded", true);
        window.location.reload();
      }
    }

    if (country) {
      if (country.toUpperCase() in countryList) {
        setSelectedCountry(country.toLowerCase());
      }
    } else {
      if (window.sessionStorage) {
        let sessionCountry = sessionStorage.getItem("country");
        if (sessionCountry) {
          if (sessionCountry.toUpperCase() in countryList) {
            setSelectedCountry(sessionCountry.toLowerCase());
          }
        }
      }
    }
  }, [country]);

  const handleSelectedCountry = ({ value, label }) => {
    history.push(value);
    setSelectedCountry(value);
    if (window.sessionStorage) {
      let sessionCountry = sessionStorage.getItem("country");
      if (sessionCountry !== value) {
        sessionStorage.setItem("country", value);
      }
    }
    return true;
  };

  const handlePayments = (e) => {
    if (e.target.checked) {
      if (!selectedPayments.includes(e.target.name)) {
        setSelectedPayments((existing) => [...existing, e.target.name]);
      }
    } else {
      setSelectedPayments((existing) =>
        existing.filter((item) => item !== e.target.name)
      );
    }
  };

  React.useEffect(() => {
    if (selectedCountry) {
      const newReorderedBuyingMethods = reorderFeaturedCountry(
        selectedCountry,
        buyingMethods
      ).map((method) => {
        const hasBuyingOptions =
          intersect(method.payments, selectedPayments).length &&
          intersect(method.countries, ["default", selectedCountry]).length;

        return { ...method, hasBuyingOptions };
      });

      setReorderedBuyingMethods(newReorderedBuyingMethods);
    }
  }, [selectedCountry, selectedPayments]);

  React.useEffect(() => {
    if (selectedCountry) {
      const defaultValue = formattedCountries.find((country) => {
        return country.value === selectedCountry;
      });
      if (defaultValue) {
        setDefaultValue(defaultValue);
      }
    }
  }, [selectedCountry]);

  return (
    <>
      <div className="home container">
        <div className="text-center title-container">
          <h2 className="m-0 h3 font-montserrat text-primary">
            Welcome to MultiversX!
          </h2>
          <h3 className="m-0 h5 font-montserrat">
            Purchase eGold using one of the options below.
          </h3>
        </div>

        <div className="row d-flex flex-grow-1">
          <div className="col-12 col-lg-4 d-flex flex-column options-panel">
            <div className="card card-steps mb-4">
              <div className="card-header border-bottom">
                <h3 className="h6 m-0">
                  Here are the key steps to buying EGLD:
                </h3>
              </div>
              <div className="card-body steps d-flex flex-column align-items-center">
                <div className="d-flex flex-column">
                  <div className="step d-flex align-items-center mb-3">
                    <div className="step-no d-flex justify-content-center align-items-center">
                      1
                    </div>
                    <div className="step-content text-left ml-3">
                      <h4 className="h6 mb-1">Get a wallet</h4>
                      <p className="mb-0">
                        Before buying you need a MultiversX address. You can
                        create a MultiversX wallet and address at{" "}
                        <a
                          href="https://wallet.multiversx.com"
                          rel="noopener noreferrer"
                        >
                          https://wallet.multiversx.com
                        </a>
                        .
                      </p>
                    </div>
                  </div>

                  <div className="step d-flex align-items-center mb-3">
                    <div className="step-no d-flex justify-content-center align-items-center">
                      2
                    </div>
                    <div className="step-content text-left ml-3">
                      <h4 className="h6 mb-1">Purchase EGLD</h4>
                      <p className="mb-0">
                        You can easily purchase EGLD with a card or bank
                        transfer.
                      </p>
                    </div>
                  </div>

                  <div className="step d-flex align-items-center">
                    <div className="step-no d-flex justify-content-center align-items-center">
                      3
                    </div>
                    <div className="step-content text-left ml-3">
                      <h4 className="h6 mb-1">Stake your EGLD</h4>
                      <p className="mb-0">
                        Receive up to 11% APR rewards for{" "}
                        <a
                          href="https://multiversx.com/blog/egold-delegation-waiting-list-guide"
                          rel="noopener noreferrer"
                        >
                          staking and securing
                        </a>{" "}
                        the MultiversX network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-country mb-4">
              <div className="card-header border-bottom">
                <h3 className="h6 m-0">Where are you from?</h3>
              </div>
              <div className="card-body d-flex align-items-center">
                <Select
                  name="country"
                  className="w-100"
                  classNamePrefix="select-search"
                  value={defaultValue}
                  isSearchable={true}
                  options={formattedCountries}
                  onChange={handleSelectedCountry}
                  placeholder="Choose your Country"
                  noOptionsMessage={() => "Not Found"}
                  components={{ Option: SelectOption }}
                />
              </div>
            </div>
            <div className="card card-payment">
              <div className="card-header border-bottom">
                <h3 className="h6 m-0">How will you purchase?</h3>
              </div>
              <div className="card-body d-flex align-items-center">
                <div>
                  {paymentMethods.map((method) => {
                    return (
                      <div
                        className="custom-control custom-checkbox"
                        key={method.id}
                      >
                        <input
                          type="checkbox"
                          name={method.id}
                          className="custom-control-input"
                          onChange={handlePayments}
                          id={`payment-${method.id}`}
                          defaultChecked="true"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`payment-${method.id}`}
                        >
                          <span className="mr-1">{method.icon}</span>
                          {method.name}
                          <br />
                          <span className="text-secondary">
                            {method.description}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 content-panel mt-4 mt-lg-0">
            <div className="card">
              <div className="card-header border-bottom">
                <h3 className="h6 m-0">Based on your input, we recommend:</h3>
              </div>
              <div className="card-body d-flex flex-column">
                {reorderedBuyingMethods.map((method) => {
                  return method.hasBuyingOptions ? (
                    <div key={method.name}>
                      <a
                        href={method.link}
                        target="_blank"
                        className={`p-3 mb-3 d-flex flex-column flex-md-row buy-option border ${method.id}`}
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex flex-column text-center text-md-left align-items-center">
                          <div>
                            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                              <h4 className="mb-0">{method.name}</h4>
                              {/* {method.id === "transak" && (
                                <span className="badge font-weight-normal badge-pill badge-success px-2 ml-2 mt-1">
                                  0% Fees
                                </span>
                              )} */}
                            </div>

                            {method.description ? (
                              <p className="text-secondary">
                                {method.description}
                              </p>
                            ) : (
                              ""
                            )}
                            <div className="d-flex flex-column align-items-start">
                              <div className="mt-1 mb-2 d-flex flex-column">
                                {method.card_details ? (
                                  <span className="partner-details">
                                    Card Fees: {method.card_details}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {method.bank_details ? (
                                  <span className="partner-details">
                                    Bank Transfers Fees: {method.bank_details}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {!(
                                  method.bank_details || method.card_details
                                ) ? (
                                  <span className="partner-details">
                                    No fees
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="d-flex mx-auto m-md-0 align-items-center partner-logo">
                                {method?.hasSvgLogo ? (
                                  <img
                                    className="img-fluid "
                                    src={`/partners/svg/${method.id}-icon.svg`}
                                    alt={method.name}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid "
                                    srcSet={`/partners/1x/${method.id}-icon@1x.jpg, /partners/2x/${method.id}-icon@2x.jpg 2x,`}
                                    src={`/partners/2x/${method.id}-icon@2x.jpg`}
                                    alt={method.name}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-4 mt-md-0 mx-auto mr-md-0 align-items-center">
                          <div>
                            <button
                              type="button"
                              href={method.link}
                              className="btn btn-outline-primary btn-sm"
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null;
                })}
                {reorderedBuyingMethods.some(
                  (method) => method.hasBuyingOptions
                ) ? null : (
                  <div className="d-flex flex-column m-auto text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-primary m-1 icon-results"
                      />
                      <h4 className="mb-2">No results</h4>
                      <p className="text-secondary">
                        We don't offer a buying method that matches your current
                        selection.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
