import { countries as countryList } from "countries-list";

export const multiversXApps = [
  {
    id: "main-site",
    name: "Main site",
    to: "https://multiversx.com/",
  },
  {
    id: "wallet",
    name: "Wallet",
    to: "https://wallet.multiversx.com/",
  },
  {
    id: "explorer",
    name: "Explorer",
    to: "https://explorer.multiversx.com/",
  },
  {
    id: "x-exchange",
    name: "xExchange",
    to: "https://xexchange.com/",
  },
  {
    id: "x-launchpad",
    name: "xLaunchpad",
    to: "https://xlaunchpad.com/",
  },
  {
    id: "bridge",
    name: "Bridge",
    to: "https://bridge.multiversx.com/",
  },
  {
    id: "docs",
    name: "Docs",
    to: "https://docs.multiversx.com/",
  },
  {
    id: "maiar",
    name: "xPortal",
    to: "https://xportal.com/",
  },
];

export const countriesEU = [
  "at", // Austria
  "be", // Belgium
  "bg", // Bulgaria
  "hr", // Croatia
  "cy", // Cyprus
  "cz", // Czech Republic
  "dk", // Denmark
  "ee", // Estonia
  "fi", // Finland
  "fr", // France
  "de", // Germany
  "gr", // Greece
  "hu", // Hungary
  "ie", // Ireland
  "it", // Italy
  "lv", // Latvia
  "lt", // Lithuania
  "lu", // Luxembourg
  "mt", // Malta
  "nl", // Netherlands
  "po", // Poland
  "pt", // Portugal
  "ro", // Romania
  "sk", // Slovakia
  "si", // Slovenia
  "es", // Spain
  "se", // Sweden
];
const coinmerceCountries = [
  "al", // Albania
  "ad", // Andorra
  "at", // Austria
  "be", // Belgium
  "bg", // Bulgaria
  "hr", // Croatia
  "cz", // Czech Republic
  "dk", // Denmark
  "ee", // Estonia
  "fi", // Finland
  "fr", // France
  "gi", // Gibraltar
  "de", // Germany
  "gr", // Greece
  "va", // Vatican City
  "hu", // Hungary
  "is", // Iceland
  "ie", // Ireland
  "im", // Isle of Man
  "it", // Italy
  "je", // Jersey
  "lv", // Latvia
  "li", // Liechtenstein
  "lt", // Lithuania
  "lu", // Luxembourg
  "mk", // Macedonia
  "mt", // Malta
  "mc", // Monaco
  "nl", // Netherlands
  "no", // Norway
  "po", // Poland
  "pt", // Portugal
  "sm", // San Marino
  "sk", // Slovakia
  "si", // Slovenia
  "es", // Spain
  "se", // Sweden
  "ch", // Switzerland
  "gb", // United Kingdom
];
const moonpayBlacklistedCountries = [
  "af", // Afghanistan
  "al", // Albania
  "bs", // Bahamas
  "bd", // Bangladesh
  "bb", // Barbados
  "bo", // Bolivia
  "bw", // Botswana
  "kh", // Cambodia
  "cn", // China
  "cu", // Cuba
  "ec", // Ecuador
  "gh", // Ghana
  "is", // Iceland
  "ir", // Iran
  "jm", // Jamaica
  "mu", // Mauritius
  "mn", // Mongolia
  "mm", // Myanmar
  "ni", // Nicaragua
  "nk", // North Korea
  "pk", // Pakistan
  "pa", // Panama
  "ss", // South Sudan
  "sd", // Sudan
  "sy", // Syria
  "ug", // Uganda
  "ve", // Venezuela
  "ye", // Yemen
  "zw", // Zimbabwe
];

const moonpayCountries = [];
for (const [countryCode] of Object.entries(countryList)) {
  if (!moonpayBlacklistedCountries.includes(countryCode.toLowerCase())) {
    moonpayCountries.push(countryCode.toLowerCase());
  }
}

const rampCountries = [
  "al", // Albania
  "ad", // Andorra
  "ao", // Angola
  "ag", // Antigua and Barbuda
  "ar", // Argentina
  "au", // Australia
  "at", // Austria
  "bh", // Bahrain
  "be", // Belgium
  "bz", // Belize
  "bj", // Benin
  "bm", // Bermuda
  "bt", // Bhutan
  "ba", // Bosnia and Herzegovina
  "bw", // Botswana
  "br", // Brazil
  "bn", // Brunei
  "bg", // Bulgaria
  "bf", // Burkina Faso
  "bi", // Burundi
  "cv", // Cabo Verde
  "cm", // Cameroon
  "ca", // Canada
  "ic", // Canary Islands
  "bq", // Caribbean Netherlands
  "td", // Chad
  "cl", // Chile
  "cx", // Christmas Island
  "cc", // Cocos (Keeling) Islands
  "km", // Comoros
  "ck", // Cook Islands
  "cr", // Costa Rica
  "hr", // Croatia
  "cy", // Cyprus
  "cz", // Czechia
  "dk", // Denmark
  "dj", // Djibouti
  "dm", // Dominica
  "do", // Dominican Republic
  "eg", // Egypt
  "sv", // El Salvador
  "gq", // Equatorial Guinea
  "ee", // Estonia
  "sz", // Eswaniti
  "et", // Ethiopia
  "fk", // Falkland Island
  "fo", // Faroe Islands
  "fi", // Finland
  "fr", // France
  "gf", // French Guiana
  "pf", // French Polynesia
  "ga", // Gabon
  "gm", // Gambia
  "ge", // Georgia
  "de", // Germany
  "gh", // Ghana
  "gi", // Gibraltar
  "gr", // Greece
  "gl", // Greenland
  "gd", // Grenada
  "gp", // Guadeloupe
  "gu", // Guam
  "gt", // Guatemala
  "gg", // Guernsey
  "gn", // Guinea
  "gw", // Guinea-Bissau
  "gy", // Guyana
  "ht", // Haiti
  "hn", // Honduras
  "hk", // Hong Kong
  "hu", // Hungary
  "is", // Iceland
  "in", // India
  "ie", // Ireland
  "im", // Isle of Man
  "il", // Israel
  "it", // Italy
  "je", // Jersey
  "kz", // Kazakhstan
  "ke", // Kenya
  "ki", // Kiribati
  "kw", // Kuwait
  "la", // Laos
  "lv", // Latvia
  "lb", // Lebanon
  "lr", // Liberia
  "li", // Liechtenstein
  "lt", // Lithuania
  "lu", // Luxembourg
  "mg", // Madagascar
  "mw", // Malawi
  "my", // Malaysia
  "mv", // Maldives
  "ml", // Mali
  "mt", // Malta
  "mh", // Marshall Islands
  "mq", // Martinique
  "mr", // Mauritania
  "yt", // Mayotte
  "mx", // Mexico
  "fm", // Micronesia
  "md", // Moldova
  "mc", // Monaco
  "me", // Montenegro
  "mz", // Mozambique
  "nr", // Nauru
  "nl", // Netherlands
  "nc", // New Caledonia
  "nz", // New Zealand
  "ne", // Niger
  "ng", // Nigeria
  "nu", // Niue
  "nf", // Norfolk Island
  "mk", // North Macedonia
  "mp", // Northern Mariana Islands
  "no", // Norway
  "pg", // Papua New Guinea
  "py", // Paraguay
  "pe", // Peru
  "ph", // Philippines
  "pl", // Poland
  "pt", // Portugal
  "pr", // Puerto Rico
  "cg", // Republic of the Congo
  "ro", // Romania
  "ru", // Russia
  "rw", // Rwanda
  "re", // Réunion
  "bl", // Saint Barthélemy
  "sh", // Saint Helena
  "lc", // Saint Lucia
  "mf", // Saint Martin
  "pm", // Saint Pierre and Miquelon
  "vc", // Saint Vincent
  "sm", // San Marino
  "sn", // Senegal
  "rs", // Serbia
  "sl", // Sierra Leone
  "sg", // Singapore
  "sx", // Sint Maarten
  "sk", // Slovakia
  "si", // Slovenia
  "sb", // Solomon Islands
  "za", // South Africa
  "kr", // South Korea
  "es", // Spain
  "lk", // Sri Lanka
  "sr", // Suriname
  "se", // Sweden
  "ch", // Switzerland
  "st", // São Tomé and Príncipe
  "tj", // Tajikistan
  "tz", // Tanzania
  "th", // Thailand
  "tl", // Timor-Leste
  "tg", // Togo
  "tk", // Tokelau
  "to", // Tonga
  "tn", // Tunisia
  "tr", // Turkey
  "tm", // Turkmenistan
  "tc", // Turks and Caicos Islands
  "tv", // Tuvalu
  "ua", // Ukraine
  "ae", // United Arab Emirates
  "gb", // United Kingdom
  "us", // United States
  "uy", // Uruguay
  "uz", // Uzbekistan
  "va", // Vatican
  "wf", // Wallis and Futuna
  "zm", // Zambia
  "ax", // Åland Islands
];

// https://guardarian.freshdesk.com/support/solutions/articles/80001028316-what-are-the-supported-countries-regions-
const guardarianBlacklistedCountries = [
  "af", // Afghanistan
  "ai", // Anguilla
  "as", // American Samoa
  "cu", // Cuba
  "fj", // Fiji
  "gu", // Guam
  "ir", // Iran
  "nk", // North Korea
  "pw", // Palau
  "sd", // Sudan
  "sy", // Syria
  "vi", // US Virgin Islands
  "ws", // Samoa
  "ye", // Yemen

  "by", // belarus - temporary
  "cn", // China - temporary
  "ru", // Russia - temporary
  "ua", // Ukraine - temporary
];

const guardarianCountries = [];
for (const [countryCode] of Object.entries(countryList)) {
  if (!guardarianBlacklistedCountries.includes(countryCode.toLowerCase())) {
    guardarianCountries.push(countryCode.toLowerCase());
  }
}

export const paymentMethods = [
  {
    id: "card_payments",
    name: "By card",
    description: "(15 minutes delivery time)",
    icon: "💳 ",
  },
  {
    id: "bank_transfers",
    name: "With a Bank Transfer",
    description: "(1-2 business days)",
    icon: "🏛 ",
  },
];

export let buyingMethods = [
  {
    id: "changelly",
    priority: 8,
    featured_in: [],
    name: "Changelly",
    link: "https://changelly.com/buy?from=usd&to=EGLD",
    description: "",
    payments: ["card_payments"],
    countries: ["other", "ru", "gb", "nz", "au"].concat(countriesEU), // Russia, UK, New Zealand, Australia and the EU
    card_details: "5% (15 minutes delivery time)",
    bank_details: "",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "coinmerce",
    priority: 3,
    featured_in: ["be", "nl"],
    name: "Coinmerce",
    link: "https://coinmerce.io/en/elrond/",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: coinmerceCountries.concat(["other"]),
    card_details: "3.50% (15 minutes delivery time)",
    bank_details: "0 (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "coinspot",
    priority: 3,
    featured_in: [],
    name: "CoinSpot",
    link: "https://www.coinspot.com.au/buy/egld",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: ["other", "au"], // Australia
    card_details: "3.50% (15 minutes delivery time)",
    bank_details: "0.1-1% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "cryptocoinpro",
    priority: 7,
    featured_in: [],
    name: "Crypto Coin Pro",
    link: "https://checkout.cryptocoin.pro/order/elrond",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: countriesEU.concat(["other"]),
    card_details: "4% (15 minutes delivery time)",
    bank_details: "2% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "indacoin",
    priority: 6,
    featured_in: [],
    name: "Indacoin",
    link: "https://indacoin.io/buy-elrond-with-card",
    description: "",
    payments: ["card_payments"],
    countries: ["other", "ru", "gb", "nz", "au"].concat(countriesEU), // Russia, UK, New Zealand, Australia and the EU
    card_details: "4.50% (15 minutes delivery time)",
    bank_details: "",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "tokero",
    priority: 4,
    featured_in: [],
    name: "Tokero",
    link: "https://tokero.com/en/exchange/buy/egld/eur/",
    description: "",
    payments: ["bank_transfers"],
    countries: countriesEU.concat(["other"]),
    card_details: "",
    bank_details: "1% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "moonpay",
    priority: 1,
    featured_in: [],
    name: "Moonpay",
    link: "https://buy.moonpay.io/?apiKey=pk_live_wEDFuQkYokTiDDwgMZ6HWxclImBpoul5&currencyCode=EGLD&colorCode=%231B46C2&showAllCurrencies=false&enabledPaymentMethods=credit_debit_card,sepa_bank_transfer,gbp_bank_transfer,apple_pay",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: moonpayCountries.concat(["other"]),
    card_details: "3.35% (2-5 minutes delivery time)",
    bank_details: "1% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "ramp",
    priority: 1,
    featured_in: [],
    name: "Ramp",
    link: "https://app.ramp.network/?swapAsset=EGLD&hostAppName=Ramp&hostLogoUrl=https://buy.multiversx.com/apple-touch-icon.png&hostApiKey=hhboaen7qh283oancwu7e4xxpxd6coh43onfocwf",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: rampCountries.concat(["other"]),
    card_details: "2.90% (15 minutes delivery time)",
    bank_details: "1.49% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "tradesilvania",
    priority: 5,
    featured_in: [],
    name: "Tradesilvania",
    link: "https://tradesilvania.com/en/prices/elrond",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: countriesEU.concat(["other"]),
    card_details: "0.1-1% (15 minutes delivery time)",
    bank_details: "0.1-1% (1-2 business days)",
    custom_country_details: null,
    hasSvgLogo: true,
  },
  {
    id: "transak",
    priority: 0,
    featured_in: [],
    name: "Transak",
    link: "https://global.transak.com/?apiKey=09345599-5690-4f80-8c9d-9a6b84efe0aa&defaultCryptoCurrency=EGLD",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: ["other", "au", "ca", "hk", "mx", "nz", "gb", "us"].concat(
      countriesEU
    ), // Australia, Canada, Hong Kong, Mexico, New Zeeland, United Kingdom and the EU
    card_details: "3.35% (15 minutes delivery time)",
    bank_details: "1% (1-2 business days)",
    custom_country_details: {
      gb: {
        card_details: null,
        bank_details: "0.50% (less than 5 minutes)",
      },
    },
    hasSvgLogo: true,
  },
  {
    id: "guardarian",
    priority: 1,
    featured_in: [],
    name: "Guardarian",
    link: "https://guardarian.com/buy-egld",
    description: "",
    payments: ["card_payments", "bank_transfers"],
    countries: guardarianCountries.concat(["other"]),
    card_details: "2.64% + 1.50 USD (5-10 minutes delivery time)",
    bank_details: "0% + 2.49 USD (1-3 business days)",
    hasSvgLogo: true,
    custom_country_details: {
      gb: {
        card_details: "2.64% + 1.50 GBP (5-10 minutes delivery time)",
        bank_details: "0% + 2.49 GBP (1-3 business days)",
      },
      europe: {
        card_details: "2.64% + 1.50 EUR (5-10 minutes delivery time)",
        bank_details: "0% + 2.49 EUR (1-3 business days)",
      },
    },
  },
];

buyingMethods.sort((a, b) =>
  a.priority > b.priority
    ? 1
    : a.priority === b.priority
    ? a.id > b.id
      ? 1
      : -1
    : -1
);
