import { countriesEU } from "appConfig";

const reorderFeaturedCountry = (countryCode, buyingMethods) => {
  let reorderedMethods = JSON.parse(JSON.stringify(buyingMethods));

  if (countryCode) {
    let priorityMethod = reorderedMethods.find((method) => {
      if (
        countriesEU.includes(countryCode) &&
        method.custom_country_details &&
        method.custom_country_details["europe"]
      ) {
        if (
          method.custom_country_details["europe"].bank_details !== undefined
        ) {
          method.bank_details =
            method.custom_country_details["europe"].bank_details;
        }
        if (
          method.custom_country_details["europe"].card_details !== undefined
        ) {
          method.card_details =
            method.custom_country_details["europe"].card_details;
        }
      }

      if (
        method.custom_country_details &&
        method.custom_country_details[countryCode]
      ) {
        if (
          method.custom_country_details[countryCode].bank_details !== undefined
        ) {
          method.bank_details =
            method.custom_country_details[countryCode].bank_details;
        }
        if (
          method.custom_country_details[countryCode].card_details !== undefined
        ) {
          method.card_details =
            method.custom_country_details[countryCode].card_details;
        }
      }

      if (
        method.featured_in.length &&
        method.featured_in.includes(countryCode)
      ) {
        return method;
      } else {
        return false;
      }
    });
    if (priorityMethod) {
      priorityMethod.priority = 0;
    }

    reorderedMethods.sort((a, b) =>
      a.priority > b.priority
        ? 1
        : a.priority === b.priority
        ? a.id > b.id
          ? 1
          : -1
        : -1
    );
  }

  return reorderedMethods;
};

export default reorderFeaturedCountry;
