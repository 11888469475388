import * as React from "react";
import { ReactComponent as MultiversXLogoDesktop } from "../../assets/images/logo-desktop.svg";

const Logo = () => (
  <a href="/" className="d-flex align-items-center navbar-brand mr-2 pr-1">
    <MultiversXLogoDesktop className="main-logo flex-shrink-0" />
  </a>
);

export default Logo;
