import React from "react";
import { Nav } from "react-bootstrap";
import Logo from "./logo";
import AppSwitcher from "./appSwitcher";

export default function Navbar() {
  return (
    <>
      <div className="main-navbar sticky-top">
        <Nav className="align-items-stretch flex-nowrap px-md-4 navbar">
          <div className="d-flex align-items-center">
            <Logo />
          </div>
          <div className="d-flex align-items-center">
            <ul className="flex-row navbar-nav">
              <li className="nav-item d-flex">
                <AppSwitcher />
              </li>
            </ul>
          </div>
        </Nav>
      </div>
    </>
  );
}
