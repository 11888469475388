import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart";

const Footer = () => (
  <footer className="footer d-flex flex-column align-items-center justify-content-center text-muted">
    <div className="footer-inner">
      <a
        {...{
          target: "_blank",
        }}
        className="d-flex align-items-center"
        href="https://multiversx.com/"
        rel="noopener noreferrer"
      >
        Made with
        <FontAwesomeIcon icon={faHeart} className="text-danger mx-1" />
        by the MultiversX team.
      </a>
    </div>
  </footer>
);

export default Footer;
